<template>
  <div class="ChangeGoodsDetails">
    <div class="ChangeGoodsDetails_title">退货详情</div>
    <div class="order_info">
      <div>
        退换货批次号: <span>{{ queryData.logisticsNo }} </span>
      </div>
      <div>
        发起人: <span>{{ queryData.receivingName }} </span>
      </div>
      <div>
        联系电话: <span>{{ queryData.phone }} </span>
      </div>
      <div>
        供应商:<span> {{ queryData.supplierName }}</span>
      </div>
      <div>
        送货地址: <span> {{ queryData.receivingAddress }} </span>
      </div>
      <div>
        创建时间: <span> {{ queryData.receivingAddress }} </span>
      </div>
    </div>
    <a-divider />

    <div>
      <a-table
        ref="table"
        size="small"
        rowKey="id"
        :columns="tableColumns"
        :data-source="dataSource"
        :pagination="false"
        :scroll="{ x: '1000px', y: 'calc(82vh - 150px )' }"
        tableLayout="fixed"
      >
        <!-- 材料类别 -->
        <span slot="type" slot-scope="text" class="action">
          {{ text | ChangeGoodsDetailsType }}
        </span>
      </a-table>
    </div>
    <div class="AuditStatus_out">
      <div class="AuditStatus">
        {{ queryData.examineState | ExamineState }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChangeGoodsDetails",
  components: {},
  data() {
    // this.columns = columns;
    return {
      queryData: {},
      dataSource: [],
      tableColumns: [
        {
          title: "材料编号",
          width: "100px",
          dataIndex: "materialCode",
          ellipsis: true,
          align: "center",
        },
        {
          title: "类型",
          width: "100px",
          dataIndex: "type",
          scopedSlots: { customRender: "type" },
          align: "center",
        },
        {
          title: "材料类别",
          width: "100px",
          dataIndex: "material.typeName",
        },
        {
          title: "退货材料",
          // width: "90px",
          dataIndex: "material.name",
          align: "center",
          ellipsis: true,
        },
        {
          title: "型号",
          width: "150px",
          dataIndex: "material.modelCode",
          align: "center",
        },

        {
          title: "单位",
          width: "90px",
          dataIndex: "material.unit",
          align: "center",
        },
        {
          title: "退货数量",
          width: "90px",
          dataIndex: "sentNumber",
          align: "center",
        },

        {
          title: "原因",
          width: "100px",
          dataIndex: "remark",
          align: "center",
        },
      ],
      record: "",
    };
  },
  created() {
    // this.tableColumns = columns;
    this.queryData = JSON.parse(this.$route.query.query);
    console.log("this.queryData", this.queryData);
    this.dataSource = this.queryData.logisticsDetailsList;
  },
  mounted() {},
  methods: {
    onClose() {
      this.visibleDrawer = false;
    },
  },
};
</script>
<style lang="less" scoped>
.ChangeGoodsDetails_title {
  text-align: left;
  margin: 20px 0;
  font-size: 16px;
  font-weight: 800;
}
.ChangeGoodsDetails {
  margin-top: 50px;
  .ChangeGoodsDetails_steps {
    width: 80%;
    text-align: left;
  }
}
.order_info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  div {
    margin: 10px 0;
    font-weight: 600;
    width: 33%;
    text-align: left;
    span {
      color: #999999;
      padding-left: 10px;
      font-weight: 400;
    }
  }
}
.returnBtn {
  text-align: left;
  margin: 10px 0;
}
.AuditStatus_out {
  display: flex;
  justify-content: center;
  margin: 50px;
  .AuditStatus {
    font-size: 26px;
    color: #ffba07;
    border: 1px solid #ffba07;
    border-radius: 4px;
    // width: 104px;
    height: 80px;
    line-height: 80px;
    padding: 0 40px;
    font-weight: 800;
  }
}
</style>